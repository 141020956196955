const accordionQuote = document.querySelectorAll('.article__accordion-quote') ?? false;

const init = () => {
	if (!accordionQuote) {
		return;
	}

	accordionQuote.forEach((quote) => {
		quote.addEventListener('click', (e) => {
			const point = e.target.closest('.article__accordion-point');
			const answer = point.querySelector('.article__accordion-answer');
			const content = point.querySelector('.article__accordion-content');

			if (point.classList.contains('is-active')) {
				point.classList.remove('is-active');
				answer.style.height = 0;
			} else {
				point.classList.add('is-active');
				answer.style.height = `${content.offsetHeight}px`;
			}

		});
	});
};

export default {
	init,
};
