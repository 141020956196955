import './vendor';
import './components/preloader';
import vars from './helpers';
import animation from './components/animation';
import header from './components/header';
import slider from './components/slider';
import rating from './components/rating';
import accordion from './components/accordion';
import feedback from './components/feedback';
import year from './components/year';

document.addEventListener('DOMContentLoaded', () => {
	header.init();
	animation.init();
	year.init();

	if (vars.html.classList.contains('page-home')) {
		slider.init();
		rating.init();
	}

	if (vars.html.classList.contains('page-article')) {
		accordion.init();
		feedback.init();
	}
});
