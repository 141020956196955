import vars from '../helpers';

const links = document.querySelectorAll('[href^="#"]');
const selectorFadeUp = document.querySelectorAll('[data-animate="fade-up"]');
const selectorIntro = document.querySelector('[data-animate="intro"]') ?? false;
const animateDuration = 0.6;

let isAnimate = true;
let scrollTl = gsap.timeline();

const scrollTo = (current) => {
	const offset = document.querySelector(current).offsetTop - vars.header.offsetHeight;

	scrollTl.to(window, {
		duration: 0.7,
		scrollTo: offset,
		ease: 'Power3.easeOut',
	});
};

const fadeUp = (element) => {
	if (element.classList.contains('is-animate')) {
		return;
	}

	const elementDelay = element.dataset.animateDelay ? element.dataset.animateDelay : false;

	gsap.timeline().to(element, {
		onComplete() {
			element.classList.add('is-animate');
		},
		duration: animateDuration,
		delay: elementDelay ? elementDelay : 0,
		opacity: 1,
		y: 0,
		ease: 'Power1.easeOut',
		clearProps: 'all',
	});
};

const fadeIntro = (element) => {
	if (element.classList.contains('is-animate')) {
		return;
	}

	const title = element.querySelector('.intro__title');
	const description = element.querySelector('.intro__description');
	const buttons = element.querySelector('.intro__buttons');
	const logo = element.querySelector('.intro__logo');

	gsap.timeline()
		.from(title, {
			onStart() {
				element.classList.add('is-animate');
			},
			duration: animateDuration,
			opacity: 0,
			y: '50px',
			ease: 'Power3.easeOut',
		})
		.from(description, {
			duration: animateDuration,
			opacity: 0,
			y: '50px',
			ease: 'Power3.easeOut',
		}, `-=${animateDuration / 2}`)
		.from(buttons, {
			duration: animateDuration,
			opacity: 0,
			y: '50px',
			ease: 'Power3.easeOut',
		}, `-=${animateDuration / 2}`)
		.from(logo, {
			duration: animateDuration,
			opacity: 0,
			y: '50px',
			ease: 'Power3.easeOut',
		}, `-=${animateDuration / 2}`);
};

const observeInit = () => {
	const observers = new IntersectionObserver((entries, observer) => {
		entries.forEach((section) => {
			if (section.isIntersecting && isAnimate) {
				observer.unobserve(section.target);

				if (section.target.dataset.animate === 'fade-up') {
					fadeUp(section.target);
				}

				if (section.target.dataset.animate === 'intro') {
					fadeIntro(section.target);
				}
			}
		});
	}, {threshold: 0.3});

	selectorFadeUp.forEach((section) => {
		observers.observe(section);
	});

	if (selectorIntro) {
		observers.observe(selectorIntro);
	}
};

const init = () => {
	observeInit();

	links.forEach((link) => {
		link.addEventListener('click', (e) => {
			e.preventDefault();

			scrollTo(link.getAttribute('href'));
		});
	});
};

export default {
	init,
	fadeUp,
};
