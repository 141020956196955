import Swiper, {Pagination, Navigation} from 'swiper';

const init = () => {
	const quoteSlider = document.querySelector('.quote__cards') ?? false;
	const leadersHeightSlider = document.querySelector('.leaders__height') ?? false;
	const leadersCountySlider = document.querySelector('.leaders__county') ?? false;

	if (leadersHeightSlider) {
		const leadersHeightSwiper = new Swiper(leadersHeightSlider, {
			speed: 500,
			spaceBetween: 40,
			slidesPerView: 1,
			modules: [Pagination, Navigation],
			pagination: {
				el: '.leaders__pagination',
				clickable: true,
			},
			breakpoints: {
				1025: {
					spaceBetween: 40,
				},
			},
			navigation: {
				prevEl: '.leaders__height .leaders__arrow-prev',
				nextEl: '.leaders__height .leaders__arrow-next',
			},
		});
	}

	if (leadersCountySlider) {
		const leadersCountySwiper = new Swiper(leadersCountySlider, {
			speed: 500,
			spaceBetween: 40,
			slidesPerView: 1,
			modules: [Pagination, Navigation],
			pagination: {
				el: '.leaders__pagination',
				clickable: true,
			},
			breakpoints: {
				1025: {
					slidesPerView: 2,
					spaceBetween: 20,
				},
			},
			navigation: {
				prevEl: '.leaders__county .leaders__arrow-prev',
				nextEl: '.leaders__county .leaders__arrow-next',
			},
		});
	}

	if (quoteSlider) {
		const quoteSwiper = new Swiper(quoteSlider, {
			speed: 500,
			spaceBetween: 40,
			slidesPerView: 1,
			modules: [Pagination, Navigation],
			pagination: {
				el: '.quote__pagination',
				clickable: true,
			},
			breakpoints: {
				1025: {
					slidesPerView: 2,
					spaceBetween: 20,
				},
			},
			navigation: {
				prevEl: '.quote__arrow-prev',
				nextEl: '.quote__arrow-next',
			},
		});
	}
};

export default {
	init,
};
