const formInpit = document.querySelectorAll('.article__form-input, .article__form-textarea') ?? false;
const buttonSubmit = document.querySelector('.button--submit') ?? false;
const csrfToken = document.querySelector('input[name="csrfmiddlewaretoken"]')?.value;
const alarmClose = document.querySelectorAll('.article__alarm-close') || false;
const alarmSend = document.querySelector('.article__alarm--send') || false;
const alarmError = document.querySelector('.article__alarm--error') || false;

const alarmAnimate = (alarm) => {
	gsap.to(alarm, {
		onStart() {
			alarm.classList.remove('is-hidden');
		},
		duration: 0.3,
		opacity: 1,
		maxHeight: '100px',
		ease: 'Power3.easeIn',
	});
};

const submit = async (form) => {
	let formData = new FormData(form);
	let formObject = {};

	for (let [key, value] of formData) {
		formObject[key] = value;
	}

	let response = await fetch('/sendmail ', {
		method: 'POST',
		mode: 'same-origin',
		headers: {'X-CSRFToken': csrfToken},
		body: JSON.stringify(formObject),
	})
		.then(() => {
			if (response.ok) {
				buttonSubmit.classList.add('is-send');
				buttonSubmit.textContent = 'Спасибо';

				if (alarmSend) {
					alarmAnimate(alarmSend);
				}
				alarmAnimate(alarmError);
			} else {
				buttonSubmit.classList.add('is-error');
				buttonSubmit.textContent = 'Ошибка';

				if (alarmError) {
					alarmAnimate(alarmError);
				}
			}

			form.reset();
		})
		.catch(() => {
			buttonSubmit.classList.add('is-error');
			buttonSubmit.textContent = 'Ошибка';

			if (alarmError) {
				alarmAnimate(alarmError);
			}

			form.reset();
		});
};

const required = (form) => {
	let inputValue = [];

	formInpit.forEach((input) => {
		inputValue.push(input.value);
		if (!input.value) {
			input.classList.add('is-error');
		}
	});

	if (!inputValue.includes('')) {
		submit(form);
	}
};

const init = () => {
	if (!buttonSubmit) {
		return;
	}

	formInpit.forEach((input) => {
		input.addEventListener('input', () => {
			input.classList.remove('is-error');
		});
	});

	buttonSubmit.addEventListener('click', (e) => {
		if (buttonSubmit.classList.contains('is-send') || buttonSubmit.classList.contains('is-error')) {
			return;
		}

		const form = e.target.closest('.article__form');

		e.preventDefault();
		e.stopPropagation();

		required(form);
	});

	if (alarmClose) {
		alarmClose.forEach((close) => {
			close.addEventListener('click', (e) => {
				const alarm = e.target.closest('.article__alarm');

				gsap.to(alarm, {
					onComplete() {
						alarm.classList.add('is-hidden');
					},
					duration: 0.3,
					opacity: 0,
					maxHeight: 0,
					ease: 'Power3.easeOut',
				});
			});
		});
	}
};

export default {
	init,
};
