const form = document.querySelector('.rating__form') ?? false;
const yearSelect = document.querySelector('.rating__form-select[name="year"]');
const yearText = document.querySelector('.rating__form-text--year');
const regionSelect = document.querySelector('.rating__form-select[name="region"]');
const regionText = document.querySelector('.rating__form-text--region');
const industrySelect = document.querySelector('.rating__form-select[name="industry"]');
const industryText = document.querySelector('.rating__form-text--industry');
const searchInput = document.querySelector('.rating__form-input');
const sectionCard = document.querySelector('.rating__section-card');
const reset = document.querySelector('.rating__reset');
const loader = document.querySelector('.rating__loader');
const csrfToken = document.querySelector('input[name="csrfmiddlewaretoken"]') ? document.querySelector('input[name="csrfmiddlewaretoken"]').value : null;
const actualYear = 2024;

let dictionaries;
let universities;

let universitiesOptionsBody = {
	q: '',
	year: actualYear,
};

const dictionariesFetchOptions = {
	method: 'GET',
	mode: 'same-origin',
	headers: {'X-CSRFToken': csrfToken},
};

let universitiesFetchOptions = () => {
	return {
		method: 'POST',
		mode: 'same-origin',
		headers: {'X-CSRFToken': csrfToken},
		body: JSON.stringify(universitiesOptionsBody),
	};
};

const debounce = (fn, delay) => {
	let timeoutId;

	return (...args) => {
		clearTimeout(timeoutId);
		timeoutId = setTimeout(() => {
			fn(...args);
		}, delay);
	};
};

const showLoader = (status) => {
	if (status) {
		loader.classList.remove('is-hidden');
	} else {
		loader.classList.add('is-hidden');
	}
};

const showReset = (status) => {
	if (status) {
		reset.classList.remove('is-hidden');
	} else {
		reset.classList.add('is-hidden');
	}
};

const filterDisable = (status) => {
	if (status) {
		yearSelect.setAttribute('disabled', 'disabled');
		regionSelect.setAttribute('disabled', 'disabled');
		industrySelect.setAttribute('disabled', 'disabled');
		searchInput.setAttribute('disabled', 'disabled');
	} else {
		yearSelect.removeAttribute('disabled');
		regionSelect.removeAttribute('disabled');
		industrySelect.removeAttribute('disabled');
		searchInput.removeAttribute('disabled');
	}
};

const removeCard = () => {
	const cards = document.querySelectorAll('.rating__card');
	cards.forEach((card) => card.remove());
};

const showCardPoint = () => {
	const buttons = document.querySelectorAll('.rating__card-button .button');

	buttons.forEach((button) => {
		const parrent = button.closest('.rating__card-button');
		const points = button.closest('.rating__card-points').querySelectorAll('.rating__card-point.is-hidden');
		let tl = gsap.timeline();

		button.addEventListener('click', () => {
			tl.to(parrent, {
				onComplete() {
					parrent.remove();
				},
				duration: 0.3,
				opacity: 0,
				ease: 'Power1.easeOut',
				clearProps: 'all',
			});

			points.forEach((point, index) => {
				tl.from(point, {
					onStart() {
						point.classList.remove('is-hidden');
					},
					duration: 0.3,
					opacity: 0,
					ease: 'Power1.easeOut',
					clearProps: 'all',
				}, `${index > 0 ? '-=10%' : ''}`);
			});
		});
	});
};

const innerCard = (card) => {
	let inner = '';
	let points = '';
	let button = '';
	let index = 1;

	universities.forEach((university) => {
		if (card.title === university.rating_category) {
			points += `
			<a class="rating__card-point ${index > 5 ? 'is-hidden' : ''}" href="${university.vuz_link}">
				<div class="rating__card-logo ${!university.logo ? 'is-hidden' : ''}"><img src="${university.logo}" alt="${university.title}"></div>
				<div class="rating__card-description">
					<div class="rating__card-university">${university.title}</div>
					<div class="rating__card-arrow ${university.old_rating && university.rating_change === 'down' ? 'rating__card-arrow--down' : university.old_rating && university.rating_change === 'up' ? '' : 'is-hidden'}"><span></span>${university.old_rating}</div>
					<div class="rating__card-region"><span></span>${university.region}</div>
				</div>
				</a>
			`;

			if (index > 5) {
				button = `
				<div class="rating__card-button">
					<button class="button button--card" type="button"><span>Показать больше</span></button>
				</div>
				`;
			}

			index += 1;
		}
	});

	inner = `
	<div class="rating__card-score"><span>${card.title}</span></div>
	<div class="rating__card-points">${points}${button}</div>
    `;

	return inner;
};

const createCard = (card) => {
	const create = document.createElement('div');

	create.className = 'rating__card';
	create.dataset.id = card.id;
	create.innerHTML = innerCard(card);

	return create;
};

const addCard = () => {
	dictionaries.categories.forEach((category) => {
		let ratingCategory = false;

		universities.filter((university) => {
			if (category.title === university.rating_category) {
				ratingCategory = true;
			}
		});

		if (ratingCategory) {
			sectionCard.append(createCard(category));
		}
	});

	const cards = document.querySelectorAll('.rating__card');

	if (cards.length === 0) {
		showReset(true);
	}

	showCardPoint();
};

const removeOption = (year) => {
	const formOptions = form.querySelectorAll('option');

	formOptions.forEach((option) => option.remove());

	delete universitiesOptionsBody.region;
	delete universitiesOptionsBody.industry;

	searchInput.value = '';
	yearSelect.value = '';
	yearText.innerText = 'Год';
	regionSelect.value = '';
	regionText.innerText = 'Регион';
	industrySelect.value = '';

	if (Number(year) === Number(actualYear)) {
		industryText.innerText = 'Отрасль';
	} else {
		industryText.innerText = 'Классификация';
	}
};

const createOption = (id, title) => {
	const option = document.createElement('option');

	option.value = id;
	option.innerText = title;

	return option;
};

const addOption = (year) => {
	dictionaries.years.forEach((el) => {
		yearSelect.appendChild(createOption(el, el));
	});

	yearText.innerText = `Год: ${year ? year : actualYear}`;
	yearSelect.value = year ? year : actualYear;

	regionSelect.appendChild(createOption('', 'Все регионы'));

	dictionaries.regions.forEach((el) => {
		regionSelect.appendChild(createOption(el.id, el.title));
	});

	if (!year || Number(year) === Number(actualYear)) {
		industrySelect.appendChild(createOption('', 'Все отрасли'));
	} else {
		industrySelect.appendChild(createOption('', 'Вся классификация'));
	}

	dictionaries.industries.forEach((el) => {
		industrySelect.appendChild(createOption(el.id, el.title));
	});
};

let getDictionaries = async (year) => {
	await fetch(`/dictionaries${csrfToken ? `?year=${year}` : '.json'}`, csrfToken ? dictionariesFetchOptions : {method: 'GET'})
		.then((response) => response.json())
		.then((dictionariesData) => {
			dictionaries = dictionariesData;

			addOption(year);
		})
		.catch((error) => console.error(error));
};

let getUniversities = async () => {
	await fetch(`/universities${csrfToken ? '' : '.json'}`, csrfToken ? universitiesFetchOptions() : {method: 'GET'})
		.then((response) => response.json())
		.then((universitiesData) => {
			universities = universitiesData;

			showLoader(false);
			filterDisable(false);
			addCard();
		})
		.catch((error) => console.error(error));
};

const getData = async () => {
	const [universitiesResponse, dictionariesResponse] = await Promise.all([
		fetch(`/universities${csrfToken ? '' : '.json'}`, csrfToken ? universitiesFetchOptions() : {method: 'GET'}),
		fetch(`/dictionaries${csrfToken ? '?year=2024' : '.json'}`, csrfToken ? dictionariesFetchOptions : {method: 'GET'}),
	]);

	const universitiesJson = await universitiesResponse.json();
	const dictionariesJson = await dictionariesResponse.json();

	return [universitiesJson, dictionariesJson];
};

const init = () => {
	if (!form) {
		return;
	}

	getData()
		.then(([universitiesData, dictionariesData]) => {
			universities = universitiesData;
			dictionaries = dictionariesData;

			addOption(false);
			addCard();
		})
		.catch((error) => console.error(error));

	searchInput.addEventListener('input', debounce((e) => {
		if (e.target.value.length < 3 && e.target.value.length !== 0) {
			return;
		}

		universitiesOptionsBody.q = e.target.value;

		removeCard();
		showReset(false);
		showLoader(true);
		filterDisable(true);
		setTimeout(() => {
			getUniversities();
		}, 500);
	}, 500));

	yearSelect.addEventListener('change', (event) => {
		const target = event.target.value;

		yearText.innerText = `Год: ${target}`;

		if (target) {
			universitiesOptionsBody.q = '';
			universitiesOptionsBody.year = target;
		} else {
			universitiesOptionsBody.year = actualYear;
		}

		form.reset();
		removeOption(target);
		getDictionaries(target ? target : actualYear);

		removeCard();
		showReset(false);
		showLoader(true);
		filterDisable(true);
		setTimeout(() => {
			getUniversities();
		}, 500);
	});

	regionSelect.addEventListener('change', (event) => {
		const target = event.target.value;
		const title = target ? dictionaries.regions.find((region) => region.id === Number(target)).title : '';

		regionText.innerText = target ? title : 'Регион';

		if (target) {
			universitiesOptionsBody.region = Number(target);
		} else {
			delete universitiesOptionsBody.region;
		}

		removeCard();
		showReset(false);
		showLoader(true);
		filterDisable(true);
		setTimeout(() => {
			getUniversities();
		}, 500);
	});

	industrySelect.addEventListener('change', (event) => {
		const target = event.target.value;
		const title = target ? dictionaries.industries.find((industries) => industries.id === Number(target)).title : '';

		industryText.innerText = target ? title : universitiesOptionsBody.year === actualYear ? 'Отрасль' : 'Классификация';

		if (target) {
			universitiesOptionsBody.industry = Number(target);
		} else {
			delete universitiesOptionsBody.industry;
		}

		removeCard();
		showReset(false);
		showLoader(true);
		filterDisable(true);
		setTimeout(() => {
			getUniversities();
		}, 500);
	});

	reset.addEventListener('click', () => {
		universitiesOptionsBody.q = '';
		universitiesOptionsBody.year = actualYear;
		delete universitiesOptionsBody.region;
		delete universitiesOptionsBody.industry;

		form.reset();

		yearSelect.value = actualYear;
		yearText.innerText = `Год: ${actualYear}`;
		regionText.innerText = 'Регион';
		industryText.innerText = 'Отрасль';

		showReset(false);
		showLoader(true);
		filterDisable(true);
		setTimeout(() => {
			getUniversities();
		}, 500);
	});
};

export default {
	init,
};
